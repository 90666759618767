<template>
  <div class="row justify-content-center h-100">
    <div class="col-lg-10 col-xl-12">
      <transition mode="out-in" name="slide-fade">
        <div class="d-block be-card h-100">
          <Loading v-if="loading" />

          <template v-if="notifications.length && !loading">
            <h4 class="with-divider p-4 f-w-600 blue">
              <span class="blue">{{ $t("notifications") }}</span>
            </h4>
            <!--------------- Desktop ---------------->
            <div
              class="table-responsive d-none d-sm-block table-body pr-4 pl-4 h-500"
            >
              <div
                v-for="(notification, index) in notifications"
                :key="index"
                class="row f-w-600 my-3 m-0 p-3"
              >
                <div class="px-0 col d-flex align-items-center">
                  <span
                    :class="notification.model"
                    class="icon-info-fill be-text-primary"
                  />
                  <div class="ml-3">
                    <p class="text-capitalize mb-3 note-title">
                      {{ notification.titre }}
                    </p>
                    <p class="d-block m-0 note-label f-w-600">
                      {{ notification.label }}
                    </p>
                  </div>
                </div>
                <div class="px-0 col d-flex justify-content-end">
                  <span class="n-time text-muted note-time">
                    <!-- <i class="icon feather icon-clock"></i> -->
                    {{
                      notification.created_at | moment("DD MMMM, YYYY - HH:MM")
                    }}
                  </span>
                </div>
              </div>
            </div>
            <!--------------- Mobile ---------------->
            <div class="d-sm-none mb-2 table-body">
              <div
                v-for="(notification, index) in notifications"
                :key="'mob-' + index"
                class="row border-bottom m-3 m-0 py-3"
              >
                <div class="d-flex p-1">
                  <span
                    :class="notification.model"
                    class="icon-info-fill be-text-primary"
                  />

                  <div class="col ml-3 p-0">
                    <div class="d-flex justify-content-between">
                      <p class="text-capitalize note-title">
                        {{ notification.titre }}
                      </p>
                      <span class="text-muted small note-time">
                        <!-- <i class="icon feather icon-clock"></i> -->
                        {{
                          notification.created_at
                            | moment("DD MMMM, YYYY - HH:MM")
                        }}
                      </span>
                    </div>
                    <p class="d-block m-0 note-label f-w-600">
                      {{ notification.label }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <!-- <BasePagination
              :current_page="current_page"
              :last_page="last_page"
              @page="getNotificationList"
            /> -->
          </template>
          <NoContent
            v-else
            :class="{ 'h-100': notifications.length === 0 }"
            :icon="'icon-bell'"
            :text="'No Data'"
          />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { emptyListModel } from "../../helpers/constants";
import NoContent from "../../components/common/NoContent";
import Loading from "../../components/common/Loading";

export default {
  name: "Notification",
  components: { NoContent, Loading },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState("auth", ["metaNotification"]),
    notifications() {
      return this.metaNotification ? this.metaNotification.data : [];
    },
    current_page() {
      return this.metaNotification ? this.metaNotification.current_page : null;
    },
    last_page() {
      return this.metaNotification ? this.metaNotification.last_page : null;
    },
    listModel() {
      return emptyListModel.message;
    },
  },
  created() {
    this.getNotificationList(1);
  },

  methods: {
    ...mapActions("auth", ["getNotifications"]),

    getNotificationList(page) {
      this.loading = true;
      this.getNotifications(page).then(() => {
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped>
.icon-info-fill:before {
  font-size: 36px;
}

.d-sm-none > .row {
  display: block;
}

.h-500 {
  height: 500px;
}

.note-time {
  font-size: 12px;
}

.note-title {
  font-size: 14px;
  margin: 0 !important;
}

.note-label {
  font-size: 12px;
  margin: 0 !important;
}
</style>
